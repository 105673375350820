<template>
  <div class="mx-auto my-auto text-center" v-if="!viewAttuale">
    <div class="spinner-border text-gray-600" role="status"></div>
    <span class="text-gray-600 ps-3 fs-2">Loading</span>
  </div>
</template>
<script>
import { defineComponent, computed, onBeforeMount, watch } from "vue";
export default defineComponent({
  name: "Loader",
});
</script>
